var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("Advertisement")])])])]),_c('CCardBody',[_c('div',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_c('b',[_vm._v("Admob Ads Setting")])])]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Inter Ad Unit","vid":"inter_ads_unit_android","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Inter Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"inter_ads_unit_android","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.inter_ads_unit_android),callback:function ($$v) {_vm.$set(_vm.formData, "inter_ads_unit_android", $$v)},expression:"formData.inter_ads_unit_android"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Native Ad Unit","vid":"native_ad_unit_android","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Native Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"native_ad_unit_android","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.native_ad_unit_android),callback:function ($$v) {_vm.$set(_vm.formData, "native_ad_unit_android", $$v)},expression:"formData.native_ad_unit_android"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Banner Ad Unit","vid":"banner_ad_unit_android","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Banner Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"banner_ad_unit_android","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.banner_ad_unit_android),callback:function ($$v) {_vm.$set(_vm.formData, "banner_ad_unit_android", $$v)},expression:"formData.banner_ad_unit_android"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Reward Ad Unit","vid":"reward_ad_unit_android","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Reward Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"reward_ad_unit_android","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.reward_ad_unit_android),callback:function ($$v) {_vm.$set(_vm.formData, "reward_ad_unit_android", $$v)},expression:"formData.reward_ad_unit_android"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Open Ad Unit","vid":"open_ad_unit_android","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Open Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"open_ad_unit_android","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.open_ad_unit_android),callback:function ($$v) {_vm.$set(_vm.formData, "open_ad_unit_android", $$v)},expression:"formData.open_ad_unit_android"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_c('b',[_vm._v("Adx Ads Setting")])])]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Inter Ad Unit","vid":"inter_ads_unit_ios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Inter Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"inter_ads_unit_ios","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.inter_ads_unit_ios),callback:function ($$v) {_vm.$set(_vm.formData, "inter_ads_unit_ios", $$v)},expression:"formData.inter_ads_unit_ios"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Native Ad Unit","vid":"native_ad_unit_ios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Native Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"native_ad_unit_ios","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.native_ad_unit_ios),callback:function ($$v) {_vm.$set(_vm.formData, "native_ad_unit_ios", $$v)},expression:"formData.native_ad_unit_ios"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Banner Ad Unit","vid":"banner_ad_unit_ios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Banner Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"banner_ad_unit_ios","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.banner_ad_unit_ios),callback:function ($$v) {_vm.$set(_vm.formData, "banner_ad_unit_ios", $$v)},expression:"formData.banner_ad_unit_ios"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Reward Ad Unit","vid":"reward_ad_unit_ios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Reward Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"reward_ad_unit_ios","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.reward_ad_unit_ios),callback:function ($$v) {_vm.$set(_vm.formData, "reward_ad_unit_ios", $$v)},expression:"formData.reward_ad_unit_ios"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Open Ad Unit","vid":"open_ad_unit_ios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Open Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"open_ad_unit_ios","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.open_ad_unit_ios),callback:function ($$v) {_vm.$set(_vm.formData, "open_ad_unit_ios", $$v)},expression:"formData.open_ad_unit_ios"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_c('b',[_vm._v("Facebook Ads Setting")])])]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Inter Ad Unit","vid":"facebook_inter_ad_unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Inter Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"facebook_inter_ad_unit","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.facebook_inter_ad_unit),callback:function ($$v) {_vm.$set(_vm.formData, "facebook_inter_ad_unit", $$v)},expression:"formData.facebook_inter_ad_unit"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Native Ad Unit","vid":"facebook_native_ad_unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Native Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"facebook_native_ad_unit","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.facebook_native_ad_unit),callback:function ($$v) {_vm.$set(_vm.formData, "facebook_native_ad_unit", $$v)},expression:"formData.facebook_native_ad_unit"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Banner Ad Unit","vid":"facebook_banner_ad_unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Banner Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"facebook_banner_ad_unit","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.facebook_banner_ad_unit),callback:function ($$v) {_vm.$set(_vm.formData, "facebook_banner_ad_unit", $$v)},expression:"formData.facebook_banner_ad_unit"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Reward Ad Unit","vid":"facebook_reward_ad_unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Reward Ad Unit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"facebook_reward_ad_unit","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.facebook_reward_ad_unit),callback:function ($$v) {_vm.$set(_vm.formData, "facebook_reward_ad_unit", $$v)},expression:"formData.facebook_reward_ad_unit"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_c('b',[_vm._v("Unity Ads Setting")])])]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"App Id/Game Id","vid":"app_game_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" App Id/Game Id "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"app_game_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.app_game_id),callback:function ($$v) {_vm.$set(_vm.formData, "app_game_id", $$v)},expression:"formData.app_game_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Intertitial Interval","vid":"intertitial_interval","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Intertitial Interval "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"intertitial_interval","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.intertitial_interval),callback:function ($$v) {_vm.$set(_vm.formData, "intertitial_interval", $$v)},expression:"formData.intertitial_interval"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Ads Type","vid":"ad_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ads Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-select',{attrs:{"type":"text","id":"ad_type","state":errors[0] ? false : (valid ? true : null),"options":[
                                                                  { text: 'Google Admob', value: 'google_admob' },
                                                                  { text: 'Googole Adx', value: 'google_adx' },
                                                                  { text: 'Facebook', value: 'facebook' },
                                                                  { text: 'Aplovin', value: 'aplovin' } ]},model:{value:(_vm.formData.ad_type),callback:function ($$v) {_vm.$set(_vm.formData, "ad_type", $$v)},expression:"formData.ad_type"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)])])]}}])})],1)],1)],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }